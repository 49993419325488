var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.options
    ? _c(
        "div",
        {
          staticClass: "image-upload flex-container",
          class: { "image-uploaded": _vm.model }
        },
        [
          !_vm.model
            ? [
                _c(
                  "div",
                  { staticClass: "upload-btn-wrapper" },
                  [
                    _c("texas-history-button", {
                      attrs: { text: _vm.$t(_vm.options.text), secondary: true }
                    }),
                    _c("input", {
                      attrs: { type: "file" },
                      on: { change: _vm.onFileChange }
                    })
                  ],
                  1
                )
              ]
            : [
                _vm.imageSource
                  ? _c("img", {
                      staticClass: "image",
                      attrs: { src: _vm.imageSource }
                    })
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(_vm.imageName))]),
                _c("div", { staticClass: "edit-delete-icon" }, [
                  _c("div", { staticClass: "upload-btn-wrapper" }, [
                    _c("img", {
                      staticClass: "edit-icon",
                      attrs: { src: _vm.editIcon }
                    }),
                    _c("input", {
                      attrs: { type: "file" },
                      on: { change: _vm.onFileChange }
                    })
                  ]),
                  _c("img", {
                    staticClass: "delete-icon",
                    attrs: { src: _vm.deleteIcon },
                    on: { click: _vm.removeImage }
                  })
                ])
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }