<template>
  <div
    class="image-upload flex-container"
    :class="{ 'image-uploaded': model }"
    v-if="options"
  >
    <template v-if="!model">
      <div
        class="upload-btn-wrapper"
      >
        <texas-history-button :text="$t(options.text)" :secondary="true" />
        <input type="file" @change="onFileChange" />
      </div>
    </template>
    <template v-else>
      <img v-if="imageSource" class="image" :src="imageSource" />
      <span>{{ imageName }}</span>
      <div class="edit-delete-icon">
        <div class="upload-btn-wrapper">
          <img :src="editIcon" class="edit-icon" />
          <input type="file" @change="onFileChange" />
        </div>
        <img :src="deleteIcon" class="delete-icon" @click="removeImage" />
      </div>
    </template>
  </div>
</template>
<script>
import TexasHistoryButton from "@/common/TexasHistoryButton.vue";
import ImageService from "@/services/ImageService.js";
import ComponentSetterMixin from "@/components/BackOffice/Mixins/ComponentSetterMixin.js";
export default {
  name: "ImageUpload",
  mixins: [ComponentSetterMixin],
  components: {
    "texas-history-button": TexasHistoryButton,
    ImageService,
  },
  props: {
    add: {
      type: Function,
      required: false,
    },
    remove: {
      type: Function,
      required: false,
    },
    update: {
      type: Function,
      required: false,
    },
  },
  data: function () {
    return {
      imageName: "",
      updatedId: null,
      isUpdate: false,
      oldImg: null,
    };
  },
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      if (!files[0].type.includes("image")) {
        this.$store.commit("addSnackBarNotification", {
          message: this.$t("wrongFileFormatAlert"),
        });
        return;
      }
      let formData = new FormData();
      formData.append("file", files[0]);
      this.imageName = files[0].name;
      this.uploadImage(formData);
    },
    uploadImage(data) {
      this.$store.commit("updateLoadingStatus", true);
      setTimeout(() => {
        ImageService.uploadImage(data)
          .then((response) => {
            if (this.add) this.add(response.data.id);
            else if (this.update) {
              this.isUpdate = true;
              this.update(this.oldImg, response.data.id);
              this.updatedId = response.data.id;
            } else this.model = response.data.id;
            this.$store.commit("updateLoadingStatus", false);
          })
          .catch(() => {
            this.$store.commit("updateLoadingStatus", false);
            this.$store.commit("addSnackBarNotification", {
              message: this.$t("failedToUploadImage"),
              dismissable: false,
            });
          });
      }, 500);
    },
    getImageName() {
      if (!this.model) return;
      ImageService.getOriginalName(this.model)
        .then((response) => {
          this.imageName = response.data;
        })
        .catch(() => {
          this.imageName = "unknown filename";
        });
    },
    removeImage: function () {
      if (this.remove) this.remove(this.model);
      this.model = null;
    },
  },
  computed: {
    deleteIcon() {
      return require("@/assets/icons/ic_delete.svg");
    },
    editIcon() {
      return require("@/assets/icons/edit_icon.png");
    },
    imageSource() {
      return `${process.env.VUE_APP_BASE_URI}images/${this.model}`;
    },
    model: {
      get: function () {
        return !this.isUpdate
          ? this.component[this.options.model]
          : this.updatedId;
      },
      set: function (value) {
        this.setComponentPropertyValue(value);
      },
    },
  },
  mounted() {
    if (this.model) {
      this.getImageName();
      this.oldImg = this.model;
    }
  },
  watch: {
    model: function () {
      this.getImageName();
    },
  },
};
</script>

<style lang="less" scoped>
// .upload-btn-wrapper-disabled{
//   display: none;
//   height: 0px;
//   width: 0px;
// }
.image-upload.flex-container {
  align-content: center;
  align-items: center;
  text-overflow: ellipsis;
  .image-properties {
    flex-direction: column;
  }
}
.edit-delete-icon {
  display: flex;
  justify-content: space-between;
  .upload-btn-wrapper {
    margin-right: 16px;
  }
}
.delete-icon {
  margin-left: auto;
  margin-right: 16px;
  cursor: pointer;
}
.edit-icon {
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;
  height: 22px;
  margin-top: 5px;
}

.image-upload {
  width: 264px;
  height: 56px;
  position: relative;
  cursor: pointer;
}

.image {
  max-height: 32px;
  max-width: 40px;
  margin-left: 21px;
  border: 2px solid rgba(0, 0, 0, 0.1);

  width: 100%;
  height: auto;
}
span {
  height: 16px;
  max-height: 16px;
  margin-left: 19px;
  width: 150px;
  max-width: 200px;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto";
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.upload-btn-wrapper {
  margin: auto;
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-right: auto;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.shadow-setter {
  margin-top: 16px;
  img {
    width: 24px;
    height: 24px;
    margin-left: 22px;
    cursor: pointer;
    align-self: center;
  }
  span {
    height: 16px;
    width: 99px;
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.4px;
    line-height: 16px;
    text-align: left;
    margin: auto 8px;
    align-self: center;
  }
}
</style>


