<template>
    <button class="btn" :class="{'secondary': secondary}"  @click="clickHandler">{{text}}</button>
</template>
<script>
export default {
    name: 'TexasHistoryButton', 
    props:{
        text: {
            type: String, 
            required: true, 
        },
        'click-handler': {
            type: Function, 
            required: false, 
            default:  ()=>{}
        }, 
        secondary:{
            type: Boolean, 
            required: false, 
            default: false
        }
    }    
}
</script>
<style lang="less" scoped>

.btn{
    all: unset;
    height: 40px;	
    width: 128px;	
    border-radius: 20px;	
    font-size: 13.96px;	
    font-weight: 500;	
    letter-spacing: 1.25px;	
    line-height: 16px;	
    text-align: center;
    cursor: pointer;
    white-space: normal;
}
.primary{
    background-color: #0A1554;
    color: #FFFFFF;	

}
.secondary{
    background-color: #FFFFFF;
    border: 2px solid #4356FD;
    color: #3A4DFD;
}
</style>


