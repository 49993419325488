export default {
    props:{
        component: Object, 
        options : Object
    }, 
    methods:{
        setComponentPropertyValue(value, propertyName = this.options.model, language= null){
            this.$store.commit('setBuilderComponentPropertyValue',
                    {'component': this.component, 'propertyName': propertyName, 'value': value, language: language})
        }
    }
}